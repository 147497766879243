/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if previewData exists on the window, and if so we run it through a `switch` statement
 * to see if there's a dynamic query component we can use.
 *
 */

import React, { useEffect, useState } from "react";
import { DefaultLayout } from "@layout";
import { Section, Container, RawText, Pattern, Text, RichText } from "@atoms";
import { CopyBlock, ImageBlock, CopyImageBlock } from "@composites";
import { Link, Button } from "@ui";
import * as DynamicQueries from "@dynamicQueries";
import { useNotFoundPageData } from "@staticQueries";
import { toCamel } from "../prismic/dataResolvers/keysToCamel";

/* eslint react/prop-types: 0 */
const NotFoundPage = pageProps => {
  const [loaded, setLoaded] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const { hero, prefooter, meta } = useNotFoundPageData();

  useEffect(() => {
    if (window.__PRISMIC_PREVIEW_DATA__) {
      setIsPreview(true);
    } else {
      setLoaded(true);
    }
  }, []);

  if (isPreview) {
    const data = window.__PRISMIC_PREVIEW_DATA__;
    const { type } = data[Object.keys(data)[0]];

    // Gotta do some kungfu on the type name to map it to
    // our named dynamic page queries:

    // transform type name to camelCase
    let queryName = toCamel(type);
    // remove the trailing "s";
    queryName = queryName.slice(0, -1);
    // append "Query"
    queryName = `${queryName}Query`;
    // capitalize first character
    queryName = queryName.charAt(0).toUpperCase() + queryName.substring(1);
    // get comoponent from imported dynamicQueries index
    const DynamicPage = DynamicQueries[queryName];

    if (DynamicPage) {
      return <DynamicPage data={data} {...pageProps} />;
    }

    return <div>Unable to load preview.</div>;
  }

  return (
    <DefaultLayout {...pageProps} meta={meta} theme="light">
      <div style={{ opacity: loaded ? 1 : 0 }} className="relative transition">
        <Pattern
          variant="alt"
          orientation="top-right"
          className="text-gray-3 z-10"
          doAnimation
        />

        {/* Hero */}
        <Section className="relative bg-gray-5" size="xl">
          <Container>
            <div className="max-w-sm">
              <Text
                tag="label"
                options={{ style: "font-normal", lineHeight: "leading-1" }}
                className="block text-green-3 mb-5"
                style={{ fontSize: 105 }}
              >
                {hero.subheading}
              </Text>

              <RawText
                items={hero.heading.raw}
                className="text-green-2 mb-6 md:mb-8"
              />

              <RichText
                html={hero.description.html}
                className="text-gray mb-6 md:mb-10"
              />

              <Link to={hero.ctaLink.url} target={hero.ctaLink.target}>
                <Button preset="light" text={hero.ctaText} />
              </Link>
            </div>
          </Container>
        </Section>

        {/* Prefooter */}
        <Section className="bg-white">
          <Container class="relative">
            <CopyImageBlock
              orientation="left"
              className="bg-green shadow-section"
              copy={({ style }) => (
                <div className="relative overflow-hidden">
                  <CopyBlock
                    {...prefooter}
                    preset="light"
                    className="relative z-10 px-6 pb-6 sm:px-8 sm:pb-8 md:p-16"
                    style={style}
                  />
                  <Pattern
                    orientation="bottom-left"
                    size="sm"
                    className="text-gray-2"
                  />
                </div>
              )}
              image={({ className, style }) => (
                <ImageBlock
                  image={prefooter.image}
                  className={className}
                  style={style}
                />
              )}
            />
          </Container>
        </Section>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
